.b2bHead
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #333333;
}
.reg-frm{
    background: white;
    padding: 20px;
    padding-top: 90px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 20px;
    margin: auto;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}
.reg-h-con {
    background-color: #47C9D9;
    content: "";
    width: 100%;
    height: 100px;
    position: absolute;
    top: -480px;
    left: 0px;
    padding: 230px 0px;
    border-radius: 300px;
    box-shadow: 0px 7px 20px #47c9d980;
}
.reg-h-con p{
    position: absolute;
    bottom: 20px;
}
.reg-frm .reg-h-con p {
    position: absolute;
    bottom: 40px;
    top: 90% !important;
    color: #000;
    left: 42% !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}
.reg-com-h-con .reg-com-h{
    font-size: 18px !important;
    font-weight: 500;
    position: absolute;
    bottom: 40px;
    top: 90% !important;
    color: #000;
    left: 38.999999% !important;
}
.reg-com-con{
    background: white;
    padding: 20px;
    padding-top: 90px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 20px;
    margin: auto;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}
.reg-com-h-con{
    background-color: #47C9D9;
    content: "";
    width: 100%;
    height: 100px;
    position: absolute;
    top: -480px;
    left: 0px;
    padding: 230px 0px;
    border-radius: 300px;
    box-shadow: 0px 7px 20px #47c9d980;
}
.reg-cus-con{
    background: white;
    padding: 20px;
    padding-top: 90px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 20px;
    margin: auto;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}
.reg-cus{
    background-color: #47C9D9;
    content: "";
    width: 100%;
    height: 100px;
    position: absolute;
    top: -480px;
    left: 0px;
    padding: 230px 0px;
    border-radius: 300px;
    box-shadow: 0px 7px 20px #47c9d980;
}
.reg-cus-h{
    font-size: 18px !important;
    font-weight: 500 !important;
    position: absolute;
    bottom: 40px;
    top: 90% !important;
    color: #000;
    left: 41% !important;
}
.reg-cus-frm{
    padding-top: 100px;
}
.reg-u-t{
    position: relative;
    top: -120px;
}
.reg-u-t > div > p{
    color: #fff;
}
.reg-main{
    padding-top: 0px !important;
}
@media screen and ( max-width:480px){
    .reg-cus-con{
        padding: 20px !important;
    }
}
